<template>
  <div class="center-on-page">
    <h1>Wups, denne siden finnes ikke.</h1>
    <button clasS="btn" @click="toFrontpage()">Tilbake</button>
  </div>
</template>
<script lang="ts">
import { defineComponent } from "vue";
import { useRouter } from "vue-router";

export default defineComponent({
  name: "PageNotFound",
  setup() {
    const router = useRouter();

    const toFrontpage = () => {
      router.push({ path: "/" });
    };
    return { toFrontpage };
  },
});
</script>

<style scoped>
.center-on-page {
  margin: 0 auto;
  margin-top: 20%;
}
.btn {
  border: 2px solid black;
  font-weight: 500;
}
</style>
