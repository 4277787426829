
import { defineComponent } from "vue";
import { useRouter } from "vue-router";

export default defineComponent({
  name: "PageNotFound",
  setup() {
    const router = useRouter();

    const toFrontpage = () => {
      router.push({ path: "/" });
    };
    return { toFrontpage };
  },
});
